import AuthService from "./AuthService";

const CommonService = {
    ServerUrl() {
        //return "https://localhost:44326/";
        return "https://eaapi.kwworks.net/";
        //return "https://api.electrifyamericatraining.com/";
        //return "https://api.qa-electrifyamericatraining.com/";
        //return "http://3.17.207.108:8083/";
    },
    ExecuteGet(request, callback) {
        var token = AuthService.getToken();
        fetch(CommonService.ServerUrl() + 'api/' + request.Url, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(res => {
                if (callback) {
                    callback(res);
                }
            }).catch((res) => {
                if (res.message === "Failed to fetch") {
                    localStorage.clear();
                    window.location.href = "/login";
                }
                if (callback) {
                    callback(res);
                }
            });
    },
    ExecutePost(request, body, callback) {
        var token = AuthService.getToken();
        fetch(CommonService.ServerUrl() + 'api/' + request.Url, {
            method: "Post",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(body)
        }).then(res => res.json())
            .then(res => {
                if (callback) {
                    callback(res);
                }
            }).catch((res) => {
                if (res.message === "Failed to fetch") {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            });
    },
};
export default CommonService;